import React from 'react';
import cx from 'classnames';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import Slider from 'react-slick';
import { PAIDY_HOST } from 'src/constants';
import ImgTVCM from 'src/images/home-tcvm/carousel-tvcm.png';
import ImgIphone16 from 'src/images/home-tcvm/carousel-iphone16.jpg';
import ImgAmazon3 from 'src/images/home-tcvm/carousel-amazon-3.png';
import ImgPaidyCard from 'src/images/home-tcvm/carousel-paidycard.png';
import Img10thanniversary from 'src/images/home-tcvm/carousel-10th.png';
import styles from './HomeSlider.module.scss';

function NextArrow({ className, ...restProps }) {
  return <div className={cx(className, styles.nextArrow)} {...restProps} />;
}

function PrevArrow({ className, ...restProps }) {
  return <div className={cx(className, styles.prevArrow)} {...restProps} />;
}

const SETTINGS = {
  className: 'slider variable-width',
  centerPadding: '12px',
  slidesToShow: 2,
  swipe: true,
  dots: true,
  variableWidth: true,
  autoplay: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  dotsClass: styles.slickDots,
  appendDots: (dots) => (
    <div>
      <ul className={styles.dots}>{dots}</ul>
    </div>
  ),
  customPaging: () => <div className={styles.dot} />,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const SLIDES_TCVM = [
  {
    key: 'cashback 202503',
    img: require('src/images/home-tcvm/carousel-cashback-202503.png'),
    alt: 'cashback 202503',
    to: `${PAIDY_HOST}/campaign/general_cashback_202503/?utm_source=web&utm_medium=webpage&utm_campaign=GeneralCB_2503&utm_content=banner_web`,
  },
  {
    key: '10th anniversary',
    img: Img10thanniversary,
    alt: '10th anniversary',
    to: `${PAIDY_HOST}/10th-anniversary/`,
  },
  {
    key: 'TVCM',
    img: ImgTVCM,
    alt: 'TVCM banner',
    to: `${PAIDY_HOST}/daiseikaicampaign_202404/`,
  },
  {
    key: 'Apple banner',
    img: ImgIphone16,
    alt: 'Apple banner',
    to: `${PAIDY_HOST}/apple/`,
  },
  {
    key: 'Amazon banner',
    img: ImgAmazon3,
    alt: 'Amazon banner',
    to: 'https://www.amazon.co.jp/b?node=8215625051',
  },
  {
    key: 'Paidycard banner',
    img: ImgPaidyCard,
    alt: 'Paidycard banner',
    to: `${PAIDY_HOST}/paidycard/`,
  },
];

export default function HomeSlider({ slides = SLIDES_TCVM }) {
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <Slider {...SETTINGS}>
          {slides.map((slide) => (
            <OutboundLink
              key={slide.key}
              href={slide.to}
              target="_blank"
              rel="noopener noreferrer"
              className={`ga_click_carousel_banner_${slide.key.split(' ')[0]}`}
            >
              <img
                src={slide.img}
                alt={slide.alt}
                height={236}
                className={styles.img}
              />
            </OutboundLink>
          ))}
        </Slider>
      </div>
    </section>
  );
}
